import React, { useState, useEffect } from 'react';
import './App.css';
import FAQ from './components/zonezapp/FAQs';
import Download from './components/zonezapp/Download';
import ContactUs from './components/zonezapp/ContactUs';
import HowItWorks from './components/zonezapp/HowItWorks';
import UsagePolicies from './components/zonezapp/UsagePolicies';
import ZoneZappLandingPage from './components/zonezapp/Zonezapp';
import TermsAndConditions from './components/zonezapp/Terms&Conditions';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import SinglePager from './components/parent-components/single-pager/SinglePager';

import PLAY_VERIFY from './components/play-verify/play-verify';
import ZoneZappPromo from './components/zonezapp/Business';


const App = () => {

  const [theme, setTheme] = useState("light");
  // Initialize activeSection as 'home' to make "Home" active on load
  const [activeSection, setActiveSection] = useState("home");
  // Track if the user clicked a sitemap (Track manual selection)
  const [manualOverride, setManualOverride] = useState(false);
  // Initialize openModal to open & close the popup model
  const [openModal, setOpenModal] = useState(false);

  // Load theme from localStorage
  useEffect(() => {
    const savedTheme = localStorage.getItem("theme") || "light";
    setTheme(savedTheme);
    document.body.className = savedTheme;
  }, []);

  // Toggle Theme
  const toggleTheme = () => {
    const newTheme = theme === "light" ? "dark" : "light";
    setTheme(newTheme);
    document.body.className = newTheme;
    localStorage.setItem("theme", newTheme);
  };

  // Model Control
  const handleOpenModal = () => {
    setOpenModal(true);
  };

  // Navigation Tracking
  useEffect(() => {
    const handleScroll = () => {
      if (manualOverride) return; // Skip scroll updates during manual override

      const sections = document.querySelectorAll('section');
      let currentSection = 'home'; // Default section if none are active

      const scrollBottomThreshold = 50; // Define how close to the bottom is considered "bottom"

      // Check if near the bottom of the page
      if (
        window.innerHeight + window.scrollY >=
        document.documentElement.scrollHeight - scrollBottomThreshold
      ) {
        currentSection = 'contact-us'; // Set "Contact" as active when at the bottom
      } else {
        // Regular section logic
        sections.forEach((section) => {
          const rect = section.getBoundingClientRect();
          if (
            rect.top <= window.innerHeight / 2 &&
            rect.bottom >= window.innerHeight / 2
          ) {
            currentSection = section.id;
          }
        });
      }

      setActiveSection(currentSection);
    };

    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, [manualOverride]);

  // Ensure the active section updates on page load or direct navigation
  useEffect(() => {
    const currentHash = window.location.hash.replace('#', '') || 'home'; // Default to 'home' if no hash
    const targetElement = document.getElementById(currentHash);
    if (targetElement) {
      setActiveSection(currentHash); // Manually set the active section
      targetElement.scrollIntoView({ behavior: 'smooth', block: 'start' });
    }
  }, []);

  const handleNavClick = (id) => {
    setActiveSection(id);
    setManualOverride(true);

    // Scroll smoothly to the section
    document.getElementById(id).scrollIntoView({ behavior: 'smooth', block: 'start' });

    // Re-enable scroll updates after 1 second
    setTimeout(() => setManualOverride(false), 1000);
  };


  return (

    <Router>
      <div className='App'>
        <Routes>
          <Route path="/" element={ <SinglePager handleOpenModal={handleOpenModal} toggleTheme={toggleTheme} theme={theme} activeSection={activeSection} handleNavClick={handleNavClick} /> } />
          <Route path="/faqs" element={<FAQ toggleTheme={toggleTheme} />} />
          <Route path="/appflow" element={<HowItWorks toggleTheme={toggleTheme} />} />
          <Route path="/contact-us" element={<ContactUs toggleTheme={toggleTheme} />} />
          <Route path="/zonezapp" element={<ZoneZappLandingPage toggleTheme={toggleTheme} />} />
          <Route path="/usage-policies" element={<UsagePolicies toggleTheme={toggleTheme} />} />
          <Route path="/terms&conditions" element={<TermsAndConditions toggleTheme={toggleTheme} />} />
          <Route path="/play-verify" element={<PLAY_VERIFY />} />
          <Route path="/download" element={<Download toggleTheme={toggleTheme} />} />
          <Route path="/business" element={<ZoneZappPromo toggleTheme={toggleTheme} />} />
        </Routes>
      </div>
    </Router>

  )
};

export default App;
