import React, { useState, useEffect, useRef } from 'react';
import './ChatAssistant.css';
import Send from '@mui/icons-material/Send';
import Close from '@mui/icons-material/Close';
import Textsms from '@mui/icons-material/Textsms';
import ErrorIcon from '@mui/icons-material/Error';
import axios from 'axios';

import config from '../../config';
import { useTranslation } from 'react-i18next';


const ChatAssistant = ({ toggleChatAssistant }) => {
  const { t } = useTranslation();
  const [isOpen, setIsOpen] = useState(false);
  const [message, setMessage] = useState('');
  const [chatHistory, setChatHistory] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const [hasOpened, setHasOpened] = useState(false); // Track if chat window has been opened
  const textareaRef = useRef(null); // Ref for the textarea

  const toggleChat = () => {
    setIsOpen(!isOpen);
  };

  const formatMessage = (msg) => {
    return msg
      .split('. ') // Split by full stops to identify sentences
      .map((sentence, index) => {
        // Capitalize first letter of each sentence, except when it starts with 'for'
        const formattedSentence = sentence.charAt(0).toUpperCase() + sentence.slice(1).toLowerCase();

        // Special case for 'for' after a period
        if (formattedSentence.startsWith('For')) {
          return formattedSentence;
        }

        return formattedSentence;
      })
      .join('. '); // Join the sentences back together
  };

  useEffect(() => {
    // Check if chat window has opened for the first time
    if (isOpen && !hasOpened) {
      const initialMessage = {
        role: 'assistant',
        content: `${t('op.floating-button.buttons.chat-assistant-btn.chat-assistant-greetings')}`,
        timestamp: new Date().toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' })
      };
      setChatHistory((prev) => [...prev, initialMessage]);
      setHasOpened(true); // Ensure the message is only added once
    }
  }, [isOpen, hasOpened]);

  const handleInputChange = (event) => {
    setMessage(event.target.value);
    // Adjust textarea height based on content
    adjustTextareaHeight();
  };

  const handleSendMessage = async () => {
    if (!message) return;

    const timestamp = new Date().toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' });

    // Add user message to chat history
    setChatHistory((prev) => [
      ...prev,
      { role: 'user', content: message, timestamp }
    ]);

    setMessage(''); // Clear the input field
    setLoading(true); // Show loader
    setError(false); // Reset error state

    try {
      const response = await axios.post(`${config.CHAT_ASSISTANT_ENDPOINT}`, { message });
      const assistantMessage = formatMessage(response.data.result) + '<br/>';

      // Add assistant's response to chat history
      setChatHistory((prev) => [
        ...prev,
        { role: 'assistant', content: assistantMessage, timestamp: new Date().toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' }) }
      ]);
    } catch (error) {
      setError(true); // Set error state
    } finally {
      setLoading(false); // Hide loader
    }
  };

  // Handle "Enter" key press to send message
  const handleKeyDown = (event) => {
    if (event.key === 'Enter') {
      handleSendMessage();
    }
  };

  const adjustTextareaHeight = () => {
    const textarea = textareaRef.current;
    if (textarea) { // Ensure the textarea element is available
      textarea.style.height = 'auto'; // Reset height to auto to allow shrinking
      textarea.style.height = `200px`; // Set height based on scroll height
    }
  };

  return (
    <div>
      <button className="chat-toggle-button" onClick={toggleChat}>
        {isOpen ? <Close onClick={toggleChatAssistant} className='close-icon' /> : <Textsms className='textsms-icon' />}
      </button>

      {isOpen && (
        <div className="chat-window">
          <div className="chat-header">
            <h3> {t('op.floating-button.buttons.chat-assistant-btn.chat-box-header')} </h3>
            <Textsms className='textsms-icon' />
          </div>
          <div className="chat-body">
            {chatHistory.map((chat, index) => (
              <div key={index} className={chat.role === 'user' ? 'user-message' : 'assistant-message'}>
                <span dangerouslySetInnerHTML={{ __html: chat.content }} />
                <br />
                <div className="timestamp">{chat.timestamp}</div>
              </div>
            ))}

            {/* Display loader while waiting for a response */}
            {loading && (
              <div className="loader">
                <div className="dot dot1"></div>
                <div className="dot dot2"></div>
                <div className="dot dot3"></div>
                <div className="dot dot4"></div>
                <div className="dot dot5"></div>
              </div>
            )}

            {/* Display error message if an error occurs */}
            {error && (
              <div className="error-message">
                <ErrorIcon className="error-icon" />
                <span>Oops! Something went wrong. Please try again.</span>
              </div>
            )}
          </div>
          <div className="chat-footer">
            <div className="chat-input-wrapper">
              <textarea
                ref={textareaRef}
                type="text"
                placeholder={t('op.floating-button.buttons.chat-assistant-btn.chat-inputfield-placeholder')}
                value={message}
                onChange={handleInputChange}
                onKeyDown={handleKeyDown}
                className="chat-input"
              ></textarea>
              <button className="send-button" onClick={handleSendMessage}>
                <Send />
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default ChatAssistant;