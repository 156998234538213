import React from 'react';
import About from '../../about/About';
import Contact from '../../contact/Contact';
import Mission from '../../mission/Mission';
import Stepper from '../../stepper/Stepper';
import Features from '../../features/Features';
import Marketing from '../../marketing/Marketing';
import TopFooter from '../../top-footer/TopFooter';
import Testimonial from '../../testimonial/Testimonial';
import BottomFooter from '../../bottom-footer/BottomFooter';
import DialogTrigger from '../dialog-trigger/DialogTrigger';
import PrivacyPolicy from '../../privacy-policy/PrivacyPolicy';
import FloatingActionButton from '../floating-action-btn/FloatingActionBtn';



const SinglePager = ({ handleOpenModal,toggleTheme,theme,activeSection,handleNavClick }) => {
    return (
        <div className='singlepager-wrapper'>

            {/* Contains both the StickyNavbar & Hero component */}
            <DialogTrigger toggleTheme={toggleTheme} theme={theme} activeSection={activeSection} handleNavClick={handleNavClick} />

            <About />
            <Features />
            <Marketing />
            <Mission />
            <PrivacyPolicy />
            <Stepper />
            <Testimonial />
            <Contact />
            <TopFooter />
            <BottomFooter />

            {/* Contains both the BackToTop & ChatAssistant component */}
            <FloatingActionButton />

        </div>
    );
}

export default SinglePager;