import './styles/Business.css';
import { React, useRef } from 'react';
import { Trans } from 'react-i18next';
import { useTranslation } from 'react-i18next';
import { motion, useInView } from "framer-motion";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import StickyNavbarOnePager from '../sticky-navbar/StickyNavbarOnePager';
import { faStore, faUsers, faMapMarkedAlt } from "@fortawesome/free-solid-svg-icons";
import { faGlobe, faMapMarkerAlt, faBullseye, faHandshake } from "@fortawesome/free-solid-svg-icons";

/* carousel images */
import slideImage3 from "../../assets/svgs/marketing.svg";
import slideImage1 from "../../assets/svgs/local-connection-gap.svg";
import slideImage2 from "../../assets/svgs/missed-opportunities-in-the-community.svg";

/* content images */
import contentImage1 from "../../assets/svgs/WhatIsZoneZapp.svg";
import contentImage2 from "../../assets/svgs/HowZoneZappWorks.svg";
import contentImage3 from "../../assets/svgs/WhyItMatters.svg";
import contentImage4 from "../../assets/svgs/TheTakeaway.svg";


const ZoneZappPromo = ({ toggleTheme }) => {

    const ref = useRef(null);
    const { t } = useTranslation();
    const isInView = useInView(ref, { triggerOnce: true, threshold: 0.2 });

    return (

        <section id="business" className="business-wrapper vh-100">

            <StickyNavbarOnePager toggleTheme={toggleTheme} />

            <br/>
            <br/>

            <div id="myCarousel" className="carousel slide" data-bs-ride="carousel" data-bs-interval="3000">
                <div className="carousel-indicators">
                    <button type="button" data-bs-target="#myCarousel" data-bs-slide-to="0" className="active" aria-current="true" aria-label="Slide 1"></button>
                    <button type="button" data-bs-target="#myCarousel" data-bs-slide-to="1" aria-label="Slide 2"></button>
                    <button type="button" data-bs-target="#myCarousel" data-bs-slide-to="2" aria-label="Slide 3"></button>
                </div>
                <div className="carousel-inner">
                    <motion.div className="carousel-item active" data-bs-interval="3000">
                        <img src={slideImage1} />
                        <div className="container d-flex flex-column justify-content-center align-items-start">
                            <div className="carousel-caption text-start">
                                <h1 className='carousel-header'>{t('business.carousel.slide1.title')}</h1>
                                <p className='carousel-sub-header'>{t('business.carousel.slide1.subtitle')}</p>
                            </div>
                        </div>
                    </motion.div>
                    <motion.div className="carousel-item" data-bs-interval="3000">
                        <img src={slideImage2} />
                        <div className="container d-flex flex-column justify-content-center align-items-start">
                            <div className="carousel-caption text-start">
                                <h1 className='carousel-header'>{t('business.carousel.slide2.title')}</h1>
                                <p className='carousel-sub-header'>{t('business.carousel.slide2.subtitle')}</p>
                            </div>
                        </div>
                    </motion.div>
                    <motion.div className="carousel-item" data-bs-interval="3000">
                        <img src={slideImage3} />
                        <div className="container d-flex flex-column justify-content-center align-items-start">
                            <div className="carousel-caption text-start">
                                <h1 className='carousel-header'>{t('business.carousel.slide3.title')}</h1>
                                <p className='carousel-sub-header'>{t('business.carousel.slide3.subtitle')}</p>
                            </div>
                        </div>
                    </motion.div>
                </div>
                <button className="carousel-control-prev" type="button" data-bs-target="#myCarousel" data-bs-slide="prev">
                    <span className="carousel-control-prev-icon" aria-hidden="true"></span>
                    <span className="visually-hidden">Previous</span>
                </button>
                <button className="carousel-control-next" type="button" data-bs-target="#myCarousel" data-bs-slide="next">
                    <span className="carousel-control-next-icon" aria-hidden="true"></span>
                    <span className="visually-hidden">Next</span>
                </button>
            </div>

            <div className="container marketing">

                <motion.hr
                    className="featurette-divider"
                    initial={{ width: "0%", opacity: 0 }}
                    whileInView={{ width: "100%", opacity: 1 }}
                    transition={{ duration: 0.8, ease: "easeOut" }}
                    viewport={{ once: true }}
                    style={{
                        borderTop: "1px solid #cccccc",
                        width: "100%",
                        margin: "20px 0"
                    }}
                />

                <div className="row featurette" ref={ref}>
                    {/* Animated Text Container */}
                    <motion.div
                        className="col-md-7"
                        initial={{ opacity: 0, y: 50 }}
                        animate={{ opacity: 1, y: 0 }}
                        transition={{ duration: 0.8, ease: "easeOut" }}
                    >
                        {/* Animated Heading */}
                        <motion.h2
                            className="featurette-heading"
                            initial={{ opacity: 0, y: 30 }}
                            animate={{ opacity: 1, y: 0 }}
                            transition={{ duration: 0.6, ease: "easeOut", delay: 0.2 }}
                        >
                            <Trans i18nKey="business.featurette.whatIsZoneZapp.title">
                                What <span className="text-muted">is ZoneZapp?</span>
                            </Trans>
                        </motion.h2>

                        {/* Animated Paragraphs with Staggered Effect */}
                        <motion.p
                            className="lead"
                            initial={{ opacity: 0, x: -30 }}
                            animate={isInView ? { opacity: 1, x: 0 } : { opacity: 1, x: 0 }}
                            transition={{ duration: 0.7, ease: "easeOut", delay: 0.3 }}
                        >
                            {t('business.featurette.whatIsZoneZapp.description1')}
                        </motion.p>

                        <motion.p
                            className="lead"
                            initial={{ opacity: 0, x: -30 }}
                            animate={isInView ? { opacity: 1, x: 0 } : { opacity: 1, x: 0 }}
                            transition={{ duration: 0.7, ease: "easeOut", delay: 0.5 }}
                        >
                            {t('business.featurette.whatIsZoneZapp.description2')}
                        </motion.p>
                    </motion.div>

                    {/* Animated Image */}
                    <motion.div
                        className="col-md-5"
                        initial={{ opacity: 0, scale: 0.8, rotate: -5 }}
                        animate={isInView ? { opacity: 1, scale: 1, rotate: 0 } : { opacity: 1, scale: 1, rotate: 0 }}
                        transition={{ duration: 1, ease: "easeOut" }}
                    >
                        <img src={contentImage1} className="featurette-image img-fluid mx-auto" width="500" height="500" />
                    </motion.div>
                </div>

                <motion.hr
                    className="featurette-divider"
                    initial={{ width: "0%", opacity: 0 }}
                    whileInView={{ width: "100%", opacity: 1 }}
                    transition={{ duration: 0.8, ease: "easeOut" }}
                    viewport={{ once: true }}
                    style={{
                        borderTop: "1px solid #cccccc",
                        width: "100%",
                        margin: "20px 0"
                    }}
                />

                <div className="row featurette" ref={ref}>
                    {/* Animated Text Section */}
                    <motion.div
                        className="col-md-7 order-md-2"
                        initial={{ opacity: 0, y: 50 }}
                        animate={{ opacity: 1, y: 0 }}
                        transition={{ duration: 0.8, ease: "easeOut" }}
                    >
                        {/* Animated Heading */}
                        <motion.h2
                            className="featurette-heading"
                            initial={{ opacity: 0, y: 30 }}
                            animate={{ opacity: 1, y: 0 }}
                            transition={{ duration: 0.6, ease: "easeOut", delay: 0.2 }}
                        >
                            <Trans i18nKey="business.featurette2.HowZoneZappWorks.title">
                                How <span className="text-muted">ZoneZapp Works?</span>
                            </Trans>
                        </motion.h2>

                        {/* Animated Paragraphs with Staggered Effect */}
                        <motion.p
                            className="lead"
                            initial={{ opacity: 0, x: -30 }}
                            animate={isInView ? { opacity: 1, x: 0 } : { opacity: 1, x: 0 }}
                            transition={{ duration: 0.7, ease: "easeOut", delay: 0.3 }}
                        >
                            {t('business.featurette2.HowZoneZappWorks.description1')}
                        </motion.p>

                        <motion.p
                            className="lead"
                            initial={{ opacity: 0, x: -30 }}
                            animate={isInView ? { opacity: 1, x: 0 } : { opacity: 1, x: 0 }}
                            transition={{ duration: 0.7, ease: "easeOut", delay: 0.5 }}
                        >
                            {t('business.featurette2.HowZoneZappWorks.description2')}
                        </motion.p>

                        <motion.p
                            className="lead"
                            initial={{ opacity: 0, x: -30 }}
                            animate={isInView ? { opacity: 1, x: 0 } : { opacity: 1, x: 0 }}
                            transition={{ duration: 0.7, ease: "easeOut", delay: 0.7 }}
                        >
                            {t('business.featurette2.HowZoneZappWorks.description3')}
                        </motion.p>
                    </motion.div>

                    {/* Animated Image */}
                    <motion.div
                        className="col-md-5 order-md-1"
                        initial={{ opacity: 0, scale: 0.8, rotate: -5 }}
                        animate={isInView ? { opacity: 1, scale: 1, rotate: 0 } : { opacity: 1, scale: 1, rotate: 0 }}
                        transition={{ duration: 1, ease: "easeOut" }}
                    >
                        <img src={contentImage2} className="featurette-image img-fluid mx-auto" width="500" height="500" />
                    </motion.div>
                </div>

                <motion.hr
                    className="featurette-divider"
                    initial={{ width: "0%", opacity: 0 }}
                    whileInView={{ width: "100%", opacity: 1 }}
                    transition={{ duration: 0.8, ease: "easeOut" }}
                    viewport={{ once: true }}
                    style={{
                        borderTop: "1px solid #cccccc",
                        width: "100%",
                        margin: "20px 0"
                    }}
                />

                <div className="row featurette" ref={ref}>
                    {/* Animated Text Section */}
                    <motion.div
                        className="col-md-7"
                        initial={{ opacity: 0, y: 50 }}
                        animate={{ opacity: 1, y: 0 }}
                        transition={{ duration: 0.8, ease: "easeOut" }}
                    >
                        {/* Animated Heading */}
                        <motion.h2
                            className="featurette-heading"
                            initial={{ opacity: 0, y: 30 }}
                            animate={{ opacity: 1, y: 0 }}
                            transition={{ duration: 0.6, ease: "easeOut", delay: 0.2 }}
                        >
                            <Trans i18nKey="business.importance.title">
                                Why <span className="text-muted">It Matters?</span>
                            </Trans>
                        </motion.h2>

                        {/* Animated Paragraph */}
                        <motion.p
                            className="lead"
                            initial={{ opacity: 0, x: -30 }}
                            animate={isInView ? { opacity: 1, x: 0 } : { opacity: 1, x: 0 }}
                            transition={{ duration: 0.7, ease: "easeOut", delay: 0.4 }}
                        >
                            {t('business.importance.points.point1')}
                        </motion.p>

                        <motion.p
                            className="lead"
                            initial={{ opacity: 0, x: -30 }}
                            animate={isInView ? { opacity: 1, x: 0 } : { opacity: 1, x: 0 }}
                            transition={{ duration: 0.7, ease: "easeOut", delay: 0.6 }}
                        >
                            {t('business.importance.points.point2')}
                        </motion.p>

                        <motion.p
                            className="lead"
                            initial={{ opacity: 0, x: -30 }}
                            animate={isInView ? { opacity: 1, x: 0 } : { opacity: 1, x: 0 }}
                            transition={{ duration: 0.7, ease: "easeOut", delay: 0.8 }}
                        >
                            {t('business.importance.points.point3')}
                        </motion.p>
                    </motion.div>

                    {/* Animated Image */}
                    <motion.div
                        className="col-md-5"
                        initial={{ opacity: 0, scale: 0.8 }}
                        animate={isInView ? { opacity: 1, scale: 1 } : { opacity: 1, scale: 1 }}
                        transition={{ duration: 1, ease: "easeOut" }}
                    >
                        <img src={contentImage3} className="featurette-image img-fluid mx-auto" width="500" height="500" />
                    </motion.div>
                </div>

                <motion.hr
                    className="featurette-divider"
                    initial={{ width: "0%", opacity: 0 }}
                    whileInView={{ width: "100%", opacity: 1 }}
                    transition={{ duration: 0.8, ease: "easeOut" }}
                    viewport={{ once: true }}
                    style={{
                        borderTop: "1px solid #cccccc",
                        width: "100%",
                        margin: "20px 0"
                    }}
                />

                <div className="row" ref={ref}>
                    {[
                        { icon: faStore, title: `${t('business.categories.faStore.title')}`, text: `${t('business.categories.faStore.text')}` },
                        { icon: faUsers, title: `${t('business.categories.faUsers.title')}`, text: `${t('business.categories.faUsers.text')}` },
                        { icon: faMapMarkedAlt, title: `${t('business.categories.faMapMarkedAlt.title')}`, text: `${t('business.categories.faMapMarkedAlt.text')}` }
                    ].map((item, index) => (
                        <motion.div
                            className="col-lg-4"
                            key={index}
                            initial={{ opacity: 0, y: 50 }}
                            animate={{ opacity: 1, y: 0 }}
                            transition={{ duration: 0.8, ease: "easeOut", delay: index * 0.2 }}
                        >
                            {/* Animated Icon */}
                            <motion.div
                                className="icon-container"
                                initial={{ opacity: 0, scale: 0.8 }}
                                animate={isInView ? { opacity: 1, scale: 1 } : { opacity: 1, scale: 1 }}
                                transition={{ duration: 0.6, ease: "easeOut", delay: index * 0.2 }}
                            >
                                <FontAwesomeIcon icon={item.icon} size="4x" className="icon" />
                            </motion.div>

                            {/* Animated Heading */}
                            <motion.h2
                                initial={{ opacity: 0, y: 20 }}
                                animate={{ opacity: 1, y: 0 }}
                                transition={{ duration: 0.6, ease: "easeOut", delay: index * 0.3 }}
                            >
                                {item.title}
                            </motion.h2>

                            {/* Animated Paragraph */}
                            <motion.p
                                className="text-center"
                                initial={{ opacity: 0, y: 20 }}
                                animate={{ opacity: 1, y: 0 }}
                                transition={{ duration: 0.7, ease: "easeOut", delay: index * 0.4 }}
                            >
                                {item.text}
                            </motion.p>
                        </motion.div>
                    ))}
                </div>

                <motion.hr
                    className="featurette-divider"
                    initial={{ width: "0%", opacity: 0 }}
                    whileInView={{ width: "100%", opacity: 1 }}
                    transition={{ duration: 0.8, ease: "easeOut" }}
                    viewport={{ once: true }}
                    style={{
                        borderTop: "1px solid #cccccc",
                        width: "100%",
                        margin: "20px 0"
                    }}
                />

                <div className="row featurette" ref={ref}>
                    {/* Animated Text Section */}
                    <motion.div
                        className="col-md-7 order-md-2"
                        initial={{ opacity: 0, y: 50 }}
                        animate={{ opacity: 1, y: 0 }}
                        transition={{ duration: 0.8, ease: "easeOut" }}
                    >
                        {/* Animated Heading */}
                        <motion.h2
                            className="featurette-heading"
                            initial={{ opacity: 0, y: 30 }}
                            animate={{ opacity: 1, y: 0 }}
                            transition={{ duration: 0.6, ease: "easeOut", delay: 0.2 }}
                        >
                            <Trans i18nKey="business.takeAway.title">
                                The <span className="text-muted">Takeaway.</span>
                            </Trans>
                        </motion.h2>

                        {/* Animated Paragraph */}
                        <motion.p
                            className="lead"
                            initial={{ opacity: 0, x: -30 }}
                            animate={isInView ? { opacity: 1, x: 0 } : { opacity: 1, x: 0 }}
                            transition={{ duration: 0.7, ease: "easeOut", delay: 0.4 }}
                        >
                            {t('business.takeAway.description1')}
                        </motion.p>

                        <motion.p
                            className="lead"
                            initial={{ opacity: 0, x: -30 }}
                            animate={isInView ? { opacity: 1, x: 0 } : { opacity: 1, x: 0 }}
                            transition={{ duration: 0.7, ease: "easeOut", delay: 0.6 }}
                        >
                            {t('business.takeAway.description2')}
                        </motion.p>
                    </motion.div>

                    {/* Animated Image */}
                    <motion.div
                        className="col-md-5 order-md-1"
                        initial={{ opacity: 0, scale: 0.8 }}
                        animate={isInView ? { opacity: 1, scale: 1 } : { opacity: 1, scale: 1 }}
                        transition={{ duration: 1, ease: "easeOut" }}
                    >
                        <img src={contentImage4} className="featurette-image img-fluid mx-auto" width="500" height="500" />
                    </motion.div>
                </div>

                <motion.hr
                    className="featurette-divider"
                    initial={{ width: "0%", opacity: 0 }}
                    whileInView={{ width: "100%", opacity: 1 }}
                    transition={{ duration: 0.8, ease: "easeOut" }}
                    viewport={{ once: true }}
                    style={{
                        borderTop: "1px solid #cccccc",
                        width: "100%",
                        margin: "20px 0"
                    }}
                />

            </div>

            <br />

            <motion.div
                className="container text-center"
                ref={ref}
                initial={{ opacity: 0, y: 20 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ duration: 0.8, ease: "easeOut" }}
            >
                {/* Back to Top Link */}
                <motion.p
                    className="float-end"
                    initial={{ opacity: 0, y: 10 }}
                    animate={{ opacity: 1, y: 0 }}
                    transition={{ duration: 0.6, ease: "easeOut", delay: 0.2 }}
                >
                    <a className="text-secondary" href="#" style={{ transition: "color 0.3s" }}> {t('business.footer.backToTop')} </a>
                </motion.p>

                {/* Copyright & Links */}
                <motion.p
                    initial={{ opacity: 0, y: 10 }}
                    animate={{ opacity: 1, y: 0 }}
                    transition={{ duration: 0.6, ease: "easeOut", delay: 0.4 }}
                >
                    {t('op.bottom-footer.copyright')} &middot;
                    <a className="text-secondary mx-2" href="/usage-policies"> {t('business.footer.privacy')} </a> &middot;
                    <a className="text-secondary" href="/terms&conditions"> {t('business.footer.terms')} </a>
                </motion.p>
            </motion.div>

        </section>

    );
};

export default ZoneZappPromo;