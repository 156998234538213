import React, { useEffect, useRef } from 'react';
import './Features.css';
import Restore from '@mui/icons-material/Restore';
import Handshake from '@mui/icons-material/Handshake';
import Diversity3 from '@mui/icons-material/Diversity3';

import { useTranslation } from 'react-i18next';

const Features = () => {
    // Refs for each feature item
    const featuresSectionRef = useRef(null);
    const communityEngagementRef = useRef(null);
    const realTimeUpdatesRef = useRef(null);
    const enterpriseAccountsRef = useRef(null);
    const { t } = useTranslation();

    useEffect(() => {
        const options = {
            root: null, // The viewport as the root
            threshold: 0.1, // 10% visibility required to trigger
        };

        const observer = new IntersectionObserver((entries) => {
            entries.forEach((entry) => {
                if (entry.isIntersecting && entry.target.id === 'features') {
                    // Trigger animations for each feature box
                    if (communityEngagementRef.current) {
                        communityEngagementRef.current.classList.add('animate__animated', 'animate__fadeInTopLeft');
                    }
                    if (realTimeUpdatesRef.current) {
                        realTimeUpdatesRef.current.classList.add('animate__animated', 'animate__fadeIn');
                    }
                    if (enterpriseAccountsRef.current) {
                        enterpriseAccountsRef.current.classList.add('animate__animated', 'animate__fadeInTopRight');
                    }
                }
            });
        }, options);

        // Observe the features section
        if (featuresSectionRef.current) {
            observer.observe(featuresSectionRef.current);
        }

        // Cleanup observer when the component unmounts
        return () => {
            if (featuresSectionRef.current) observer.unobserve(featuresSectionRef.current);
        };
    }, []);

    return (
        <section id="features" ref={featuresSectionRef} className='features-wrapper section'>
            <div className="container px-4 py-5" id="hanging-icons">
                <h2 className="features-heading text-center pb-2"> {t('op.features.header')} </h2>

                <div className="row g-4 py-5 row-cols-1 row-cols-lg-3">
                    <div ref={communityEngagementRef} className="col d-flex align-items-start">
                        <div className="icon-square glassmorphism flex-shrink-0 me-3">
                            <Handshake className="bi" width="1.50em" height="1.50em" />
                        </div>
                        <div>
                            <h2 className='text-headings'> {t('op.features.feature-items.feature-1.heading')} </h2>
                            <p className='txt-justify'>
                                {t('op.features.feature-items.feature-1.description')}
                            </p>
                        </div>
                    </div>
                    <div ref={realTimeUpdatesRef} className="col d-flex align-items-start">
                        <div className="icon-square glassmorphism flex-shrink-0 me-3">
                            <Restore className="bi" width="1.50em" height="1.50em" />
                        </div>
                        <div>
                            <h2 className='text-headings'> {t('op.features.feature-items.feature-2.heading')} </h2>
                            <p className='txt-justify'>
                                {t('op.features.feature-items.feature-2.description')}
                            </p>
                        </div>
                    </div>
                    <div ref={enterpriseAccountsRef} className="col d-flex align-items-start">
                        <div className="icon-square glassmorphism flex-shrink-0 me-3">
                            <Diversity3 className="bi" width="1.50em" height="1.50em" />
                        </div>
                        <div>
                            <h2 className='text-headings'> {t('op.features.feature-items.feature-3.heading')} </h2>
                            <p className='txt-justify'>
                                {t('op.features.feature-items.feature-3.description')}
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default Features;