import React, { useEffect, useRef } from 'react';
import './PrivacyPolicy.css';
import AboutIMG_One from '../../assets/backgrounds/privacy-policy.png';

import { Trans } from 'react-i18next';
import { useTranslation } from 'react-i18next';


const PrivacyPolicy = () => {
    // Refs for animated elements
    const privacyPolicySectionRef = useRef(null);
    const headingRef = useRef(null);
    const textRef = useRef(null);
    const imageRef = useRef(null);
    const { t } = useTranslation();


    useEffect(() => {
        const options = {
            root: null, // Uses the viewport as the root
            threshold: 0.1, // 10% visibility required to trigger
        };

        const observer = new IntersectionObserver((entries) => {
            entries.forEach((entry) => {
                if (entry.isIntersecting && entry.target.id === 'privacy-policy') {
                    // Apply animations when the privacy policy section is visible
                    if (headingRef.current) {
                        headingRef.current.classList.add('animate__animated', 'animate__zoomIn');
                    }
                    if (textRef.current) {
                        textRef.current.classList.add('animate__animated', 'animate__fadeInRight');
                    }
                    if (imageRef.current) {
                        imageRef.current.classList.add('animate__animated', 'animate__fadeInLeft');
                    }
                }
            });
        }, options);

        // Observe the privacy policy section
        if (privacyPolicySectionRef.current) {
            observer.observe(privacyPolicySectionRef.current);
        }

        // Cleanup observer when the component unmounts
        return () => {
            if (privacyPolicySectionRef.current) observer.unobserve(privacyPolicySectionRef.current);
        };
    }, []);

    return (
        <section id="privacy-policy" ref={privacyPolicySectionRef} className="privacy-policy-wrapper">
            <div className="container">
                <div className="row featurette flex-wrapper">
                    <div className="col-md-7 order-md-2">
                        <h2 ref={headingRef} className="featurette-heading">
                            <Trans i18nKey="op.privacy-policy.header">
                                Privacy <span className="text-highlight">Policy</span>.
                            </Trans>
                        </h2>
                        <p ref={textRef} className="text">
                            {t('op.privacy-policy.sub-text')}
                        </p>
                    </div>

                    <div className="col-md-5 order-md-1">
                        <div className="privacy-policy-image-container">
                            <img ref={imageRef} className="p-policy-image-one" src={AboutIMG_One} alt="p-policy-sketch" />
                        </div>
                    </div>

                </div>
            </div>
        </section>
    );
};

export default PrivacyPolicy;