import React, { useEffect, useRef, useState } from 'react';
import './Hero.css';
import 'animate.css';
import IconButton from '@mui/material/IconButton';
import ExpandMore from '@mui/icons-material/ExpandMore';
import FileDownload from '@mui/icons-material/FileDownload';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import HeroVideoCover from '../../assets/video/Walkthrough-English.mp4';
import HeroVideoCover2 from '../../assets/video/Walkthrough-French.mp4';

import { faComment, faCheck, faLocationDot, faQuestion, faBell } from "@fortawesome/free-solid-svg-icons";

import AppstoreDark from '../../assets/svgs/appstore-dark.svg';
import AppstoreLight from '../../assets/svgs/appstore-light.svg';
import GoogleplayDark from '../../assets/svgs/googleplay-dark.svg';
import GoogleplayLight from '../../assets/svgs/googleplay-light.svg';

import DOMPurify from 'dompurify';
import { Trans } from 'react-i18next';
import Typewriter from 'typewriter-effect';
import { useTranslation } from 'react-i18next';

function Hero({ handleOpenModal }) {
    // Refs for each element to be animated
    const heroImageRef = useRef(null);
    const heroVideoRef = useRef(null);
    const typewriterRef = useRef(null);
    const leadTextRef1 = useRef(null);
    const leadTextRef2 = useRef(null);
    const leadTextRef3 = useRef(null);
    const functionalityList = useRef(null);
    const scrollMoreRef = useRef(null);

    const { t } = useTranslation();

    //const [lang, setLangListener] = useState('');

    const handleVideoClick = () => {
        if (heroVideoRef.current) {
            if (heroVideoRef.current.paused) {
                heroVideoRef.current.play();
            } else {
                heroVideoRef.current.pause();
            }
        }
    };

    useEffect(() => {
        const options = {
            root: null, // Uses the viewport as the root
            threshold: 0.2, // 20% visibility required to trigger
        };

        const observer = new IntersectionObserver((entries) => {
            entries.forEach((entry) => {
                if (entry.isIntersecting) {
                    // Add specific animation classes for each element when visible
                    if (entry.target === heroImageRef.current) {
                        entry.target.classList.add('animate__animated', 'animate__fadeInRight');
                    } else if (entry.target === heroVideoRef.current) {
                        entry.target.classList.add('animate__animated', 'animate__fadeInTopRight', 'animate__delay-2s');
                    } else if (entry.target === typewriterRef.current) {
                        entry.target.classList.add('animate__animated', 'animate__fadeInLeft');

                    } else if (entry.target === leadTextRef1.current) {
                        entry.target.classList.add('animate__animated', 'animate__fadeInLeft');
                    } else if (entry.target === leadTextRef2.current) {
                        entry.target.classList.add('animate__animated', 'animate__fadeInLeft');
                    } else if (entry.target === leadTextRef3.current) {
                        entry.target.classList.add('animate__animated', 'animate__fadeInLeft');

                    } else if (entry.target === functionalityList.current) {
                        entry.target.classList.add('animate__animated', 'animate__fadeInRight');
                    } else if (entry.target === scrollMoreRef.current) {
                        entry.target.classList.add('animate__animated', 'animate__fadeInLeft', 'animate__delay-2s');
                    }
                }
            });
        }, options);

        // Observe each element
        if (heroImageRef.current) observer.observe(heroImageRef.current);
        if (heroVideoRef.current) observer.observe(heroVideoRef.current);
        if (typewriterRef.current) observer.observe(typewriterRef.current);

        if (leadTextRef1.current) observer.observe(leadTextRef1.current);
        if (leadTextRef2.current) observer.observe(leadTextRef2.current);
        if (leadTextRef3.current) observer.observe(leadTextRef3.current);

        if (functionalityList.current) observer.observe(functionalityList.current);
        if (scrollMoreRef.current) observer.observe(scrollMoreRef.current);

        // Cleanup observer when the component unmounts
        return () => {
            if (heroImageRef.current) observer.unobserve(heroImageRef.current);
            if (heroVideoRef.current) observer.unobserve(heroVideoRef.current);
            if (typewriterRef.current) observer.unobserve(typewriterRef.current);

            if (leadTextRef1.current) observer.unobserve(leadTextRef1.current);
            if (leadTextRef2.current) observer.unobserve(leadTextRef2.current);
            if (leadTextRef3.current) observer.unobserve(leadTextRef3.current);

            if (functionalityList.current) observer.unobserve(functionalityList.current);
            if (scrollMoreRef.current) observer.unobserve(scrollMoreRef.current);
        };
    }, []);

    return (
        <section id="home" className='hero-wrapper section'>
            <div className="container col-xxl-8 px-4 py-5">
                <div className="row flex-lg-row-reverse align-items-center g-5 py-1">
                    {/* Hero image/video container */}
                    <div ref={heroImageRef} className="col-12 col-md-10 col-lg-6 hero-image-container">
                        <video
                            ref={heroVideoRef}
                            onClick={handleVideoClick}
                            muted
                            loop
                            autoPlay
                            className="hero-video-cover"
                        >
                            {
                                t('op.hero.zoneZapp-walkthrough') === 'English' ? (
                                    <source src={HeroVideoCover} type="video/mp4" />
                                ) : (
                                    <source src={HeroVideoCover2} type="video/mp4" />
                                )
                            }
                            Your browser does not support the video tag.
                        </video>
                    </div>

                    {/* Hero text and call-to-action buttons */}
                    <div className="col-lg-6 text-center text-lg-start">

                        <h2 ref={typewriterRef} class="display-5 fw-bold lh-1 mb-2 text-uppercase">
                            {/* GETTING <span class="text-muted">REAL</span> <span class="o-hue">LOCAL INFO</span> SHOULDN<span class="o-hue">’</span>T DEPEND ON WHO YOU FOLLOW<span class="o-hue">.</span> */}
                            <Typewriter
                                options={{
                                    strings: [
                                        DOMPurify.sanitize(t('op.hero.header1onHero'))
                                    ],
                                    autoStart: true,
                                    loop: true,
                                    delay: 75,
                                    deleteSpeed: 30,
                                    wrapperClassName: 'typewriter-text',
                                    cursorClassName: 'typewriter-cursor',
                                    html: true,
                                }}
                            />
                        </h2>

                        <p ref={leadTextRef1} class="lead">
                            {t('op.hero.description')}
                        </p>
                        <p ref={leadTextRef2} class="lead">
                            {t('op.hero.zonezapp_intro')}
                        </p>
                        <p ref={leadTextRef3} class="lead">
                            {t('op.hero.no_followers')}
                        </p>

                        <div ref={functionalityList} class="features-list text-start mb-4">
                            <p><span class="fw-bold">
                                <FontAwesomeIcon icon={faComment} /> {t('op.hero.how_it_works')}
                            </span></p>
                            <ul class="list-unstyled">
                                <li><span class="text-primary lead"><FontAwesomeIcon className="hero-icon" icon={faCheck} /> </span>
                                    <Trans i18nKey="op.hero.step1">
                                        <strong>Create a Zone</strong> – Select your area on the map.
                                    </Trans>
                                </li>
                                <li><span class="text-primary lead"><FontAwesomeIcon className="hero-icon" icon={faLocationDot} /> </span>
                                    <Trans i18nKey="op.hero.step2">
                                        <strong>See & Share Local Updates</strong> – Get real-time info from others on the ground.
                                    </Trans>
                                </li>
                                <li><span class="text-primary lead"><FontAwesomeIcon className="hero-icon" icon={faQuestion} /> </span>
                                    <Trans i18nKey="op.hero.step3">
                                        <strong>Ask Questions</strong> – Need info on traffic, events, or safety? Just ask.
                                    </Trans>
                                </li>
                                <li><span class="text-primary lead"><FontAwesomeIcon className="hero-icon" icon={faBell} /> </span>
                                    <Trans i18nKey="op.hero.step4">
                                        <strong>Stay Notified</strong> – Subscribe to places that matter to you.
                                    </Trans>
                                </li>
                            </ul>
                        </div>

                        {/* <h1 ref={typewriterRef} className="display-5 fw-bold lh-1 mb-3">
                            <Typewriter
                                options={{
                                    strings: [
                                        DOMPurify.sanitize(t('op.hero.header1')),
                                        DOMPurify.sanitize(t('op.hero.header2')),
                                        DOMPurify.sanitize(t('op.hero.header3')),
                                    ],
                                    autoStart: true,
                                    loop: true,
                                    delay: 75,
                                    deleteSpeed: 30,
                                    wrapperClassName: 'typewriter-text',
                                    cursorClassName: 'typewriter-cursor',
                                    html: true,
                                }}
                            />
                        </h1> */}

                        {/* <p ref={leadTextRef} className="lead">
                            {t('op.hero.sub-text')}
                        </p> */}

                        {/* <div ref={joinNowBtnRef} className="d-grid gap-2 d-md-flex justify-content-center justify-content-md-start">
                            <a
                                href="https://play.google.com/store/apps/details?id=com.zonezapp&hl=en"
                                className="btn btn-dark btn-lg px-3 py-2 mb-3 rounded-3">
                                <img src={GoogleplayLight} width={150} height={150} />
                            </a>
                            <a
                                href="https://apps.apple.com/app/zonezapp/id6593563694"
                                className="btn btn-dark btn-lg px-3 py-2 mb-3 rounded-3">
                                <img src={AppstoreLight} width={150} height={150} />
                            </a>
                        </div> */}

                        <div ref={scrollMoreRef} className="btn-link-container gap-2 d-md-flex align-items-center justify-content-center justify-content-md-start mt-3">
                            <IconButton
                                href="#about"
                                className="expand-icon-btn"
                                aria-label="Scroll for more"
                                style={{ backgroundColor: '#fff', borderRadius: '50%', padding: '12px' }}
                            >
                                <ExpandMore className="expandMore-icon" />
                            </IconButton>
                            <span className="fs-sm"> {t('op.hero.action-link')} </span>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
}

export default Hero;