import React, { useState } from 'react';
import './DialogTrigger.css';
import { Link } from 'react-router-dom';
import { Trans } from 'react-i18next';
import { useTranslation } from 'react-i18next';
import config from '../../../config';

const GetInTouchForm = ({ handleToggleContent }) => {
    const { t } = useTranslation();
    const [formData, setFormData] = useState({
        name: '',
        email: '',
        origin: 'Website'
    });
    const [formErrors, setFormErrors] = useState({ name: '', email: '' });
    const [popupMessage, setPopupMessage] = useState('');
    const [isPopupVisible, setIsPopupVisible] = useState(false);
    const [isSuccess, setIsSuccess] = useState(null);

    // Validation regex patterns
    const usernamePattern = /^[a-zA-Z0-9](?!.*[-_]{2})[a-zA-Z0-9-_]{1,18}[a-zA-Z0-9]$/;
    const emailPattern = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setFormData({
            ...formData,
            [name]: value
        });

        // Real-time validation
        if (name === 'name') validateUsername(value);
        if (name === 'email') validateEmail(value);
    };

    // Username validation function
    const validateUsername = (name) => {
        if (!name) return setFormErrors({ ...formErrors, name: `${t('zonezapp.errorhandling.formVaildation.validateUsername.requiredVaildation')}` });
        if (name.length < 3 || name.length > 20) return setFormErrors({ ...formErrors, name: `${t('zonezapp.errorhandling.formVaildation.validateUsername.lengthValidation')}` });
        if (name.toLowerCase().includes('test')) return setFormErrors({ ...formErrors, name: `${t('zonezapp.errorhandling.formVaildation.validateUsername.includesValidation')}` });
        if (!usernamePattern.test(name)) return setFormErrors({ ...formErrors, name: `${t('zonezapp.errorhandling.formVaildation.validateUsername.regexValidation')}` });
        setFormErrors({ ...formErrors, name: '' });
    };

    // Email validation function
    const validateEmail = (email) => {
        if (!email) return setFormErrors({ ...formErrors, email: `${t('zonezapp.errorhandling.formVaildation.validateEmail.requiredVaildation')}` });
        if (email.length > 254) return setFormErrors({ ...formErrors, email: `${t('zonezapp.errorhandling.formVaildation.validateEmail.lengthValidation')}` });
        if (email.toLowerCase().includes('test')) return setFormErrors({ ...formErrors, email: `${t('zonezapp.errorhandling.formVaildation.validateEmail.includesValidation')}` });
        if (!emailPattern.test(email)) return setFormErrors({ ...formErrors, email: `${t('zonezapp.errorhandling.formVaildation.validateEmail.regexValidation')}` });
        setFormErrors({ ...formErrors, email: '' });
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        // Final validation check
        validateUsername(formData.name);
        validateEmail(formData.email);

        if (formErrors.name || formErrors.email) return; // Prevent submit if errors exist

        try {
            const response = await fetch(`${config.GET_IN_TOUCH_MODEL_ENDPOINT}`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(formData)
            });

            if (response.ok) {
                setIsSuccess(true);
                setFormData({ name: '', email: '', origin: 'Website' });
                setPopupMessage(`${t('zonezapp.errorhandling.handleSubmit.responseMessage')}`);
            } else {
                const errorData = await response.json();
                setIsSuccess(false);
                setPopupMessage(`${t('zonezapp.errorhandling.handleSubmit.errorMessageOnElse')}`);
            }

        } catch (error) {
            setIsSuccess(false);
            setPopupMessage(`${t('zonezapp.errorhandling.handleSubmit.errorMessageOnCatch')}`);
        }

        setIsPopupVisible(true);
        setTimeout(() => {
            setIsPopupVisible(false);
        }, 3000);
    };

    return (
        <div className="form-container">
            <form className="p-4 p-md-5 border rounded-3 form-bg" onSubmit={handleSubmit}>
                <div className="form-floating mb-3">
                    <input
                        type="text"
                        className="form-control"
                        id="floatingInput"
                        placeholder="..."
                        name="name"
                        value={formData.name}
                        onChange={handleInputChange}
                    />
                    <label htmlFor="floatingInput">{t('zonezapp.inputfields-placeholders.username')}</label>
                    {formErrors.name && <p className="error-text" style={{ color: '#721c24' }} >{formErrors.name}</p>}
                </div>
                <div className="form-floating mb-3">
                    <input
                        type="email"
                        className="form-control"
                        id="floatingEmail"
                        placeholder="name@example.com"
                        name="email"
                        value={formData.email}
                        onChange={handleInputChange}
                    />
                    <label htmlFor="floatingEmail">{t('zonezapp.inputfields-placeholders.email-address')}</label>
                    {formErrors.email && <p className="error-text" style={{ color: '#721c24' }} >{formErrors.email}</p>}
                </div>
                <button
                    className="w-100 btn btn-lg register-btn"
                    type="submit"
                    style={{ backgroundColor: '#ff6f00', color: '#ffffff' }}
                >
                    {t('zonezapp.action-button')}
                </button>
                <hr className="my-4" />
                <small className="text-body-secondary">
                    <Trans i18nKey="zonezapp.sub-text">
                        By clicking Register Now!, you agree to the ZoneZapp <Link to="/terms&conditions" className='terms-conditions-link'>terms and conditions</Link> of use.
                    </Trans>
                </small>
            </form>

            {/* Popup for success/error messages */}
            {isPopupVisible && (
                <div
                    className="popup"
                    style={{
                        color: isSuccess ? '#155724' : '#721c24',
                        backgroundColor: isSuccess ? '#d4edda' : '#f8d7da',
                        border: isSuccess ? '1px solid #d4edda' : '1px solid #f8d7da'
                    }}
                >
                    <p className="popup-text">{popupMessage}</p>
                </div>
            )}
        </div>
    );
};

export default GetInTouchForm;