import React, { useState } from 'react';
import './styles/Zonezapp.css';
import logo from '../../logo.png';
import { Link, useNavigate } from 'react-router-dom';
import ModelCover from '../../assets/backgrounds/my-app.png';
import '../parent-components/dialog-trigger/DialogTrigger.css';
import StickyNavbarOnePager from '../sticky-navbar/StickyNavbarOnePager';

import config from '../../config';
import { Trans } from 'react-i18next';
import { useTranslation } from 'react-i18next';

const ZoneZappLandingPage = ({ toggleTheme }) => {
    const navigate = useNavigate();
    const { t } = useTranslation();

    const [formData, setFormData] = useState({
        name: '',
        email: '',
        origin: 'Website'
    });
    const [isSuccess, setIsSuccess] = useState(null);
    const [popupMessage, setPopupMessage] = useState('');
    const [isPopupVisible, setIsPopupVisible] = useState(false);
    const [errors, setErrors] = useState({ name: '', email: '' });

    const usernameRegex = /^[a-zA-Z0-9](?!.*[-_]{2})[a-zA-Z0-9-_]{1,18}[a-zA-Z0-9]$/;
    const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        let error = '';

        if (name === 'name') {
            if (!value) {
                error = `${t('zonezapp.errorhandling.formVaildation.validateUsername.requiredVaildation')}`;
            } else if (value.length < 3 || value.length > 20) {
                error = `${t('zonezapp.errorhandling.formVaildation.validateUsername.lengthValidation')}`;
            } else if (!usernameRegex.test(value)) {
                error = `${t('zonezapp.errorhandling.formVaildation.validateUsername.regexValidation')}`;
            } else if (value.toLowerCase().includes('test')) {
                error = `${t('zonezapp.errorhandling.formVaildation.validateUsername.includesValidation')}`;
            }
        } else if (name === 'email') {
            if (!value) {
                error = `${t('zonezapp.errorhandling.formVaildation.validateEmail.requiredVaildation')}`;
            }else if (value.length > 254) {
                error = `${t('zonezapp.errorhandling.formVaildation.validateEmail.lengthValidation')}`;
            } else if (!emailRegex.test(value)) {
                error = `${t('zonezapp.errorhandling.formVaildation.validateEmail.regexValidation')}`;
            } else if (value.toLowerCase().includes('test')) {
                error = `${t('zonezapp.errorhandling.formVaildation.validateEmail.includesValidation')}`;
            }
        }

        setFormData({ ...formData, [name]: value });
        setErrors({ ...errors, [name]: error });
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        if (errors.name || errors.email || !formData.name || !formData.email) {
            setPopupMessage(`${t('zonezapp.errorhandling.formVaildation.formVaildationControl')}`);
            setIsSuccess(false);
            setIsPopupVisible(true);
            setTimeout(() => setIsPopupVisible(false), 3000);
            return;
        }

        try {
            const response = await fetch(`${config.ZONEZAPP_LANDING_PAGE_ENDPOINT}`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(formData)
            });

            if (response.ok) {
                setIsSuccess(true);
                setFormData({ name: '', email: '', origin: 'Website' });
                setPopupMessage(`${t('zonezapp.errorhandling.handleSubmit.responseMessage')}`);
                navigate('/');
            } else {
                const errorData = await response.json();
                setIsSuccess(false);
                setPopupMessage(`${t('zonezapp.errorhandling.handleSubmit.errorMessageOnElse')}`);
            }
        } catch (error) {
            setIsSuccess(false);
            setPopupMessage(`${t('zonezapp.errorhandling.handleSubmit.errorMessageOnCatch')}`);
        }

        setIsPopupVisible(true);
        setTimeout(() => setIsPopupVisible(false), 3000);
    };

    return (
        <section id="zonezapp" className='landingPage-wrapper'>
            <StickyNavbarOnePager toggleTheme={toggleTheme} />
            <div className="container col-xxl-8 px-1 py-3">
                <div className="row flex-lg-row-reverse align-items-center g-5 py-5">
                    <div className="col-10 col-sm-8 col-lg-6 model-cover">
                        <img src={ModelCover} className="d-block mx-lg-auto img-fluid" alt="ZoneZapp Mockups" width="900" height="700" loading="lazy" />
                    </div>
                    <div className="col-lg-6 text-center">
                        <img src={logo} className='zonezapp-logo mx-auto' height="150" width="155" alt="ZoneZapp Logo" />
                        <span className='zonezapp-text'>ZoneZapp</span>

                        <div className="form-container">
                            <form className="p-4 p-md-5 border rounded-3 form-bg" onSubmit={handleSubmit}>
                                <div className="form-floating mb-3">
                                    <input
                                        type="text"
                                        className="form-control"
                                        id="floatingInput"
                                        placeholder="..."
                                        name="name"
                                        value={formData.name}
                                        onChange={handleInputChange}
                                    />
                                    <label htmlFor="floatingInput">{t('zonezapp.inputfields-placeholders.username')}</label>
                                    {errors.name && <small className="text-danger" style={{ color: '#721c24' }} >{errors.name}</small>}
                                </div>
                                <div className="form-floating mb-3">
                                    <input
                                        type="email"
                                        className="form-control"
                                        id="floatingEmail"
                                        placeholder="name@example.com"
                                        name="email"
                                        value={formData.email}
                                        onChange={handleInputChange}
                                    />
                                    <label htmlFor="floatingEmail">{t('zonezapp.inputfields-placeholders.email-address')}</label>
                                    {errors.email && <small className="text-danger" style={{ color: '#721c24' }} >{errors.email}</small>}
                                </div>
                                <button
                                    className="w-100 btn btn-lg register-btn"
                                    type="submit"
                                    style={{ backgroundColor: '#ff6f00', color: '#ffffff' }}
                                >
                                    {t('zonezapp.action-button')}
                                </button>
                                <hr className="my-4" />
                                <small className="text-body-secondary">
                                    <Trans i18nKey="zonezapp.sub-text">
                                        By clicking Register Now!, you agree to the ZoneZapp <Link to="/terms&conditions" className='terms-conditions-link'>terms and conditions</Link> of use.
                                    </Trans>
                                </small>
                            </form>
                            {isPopupVisible && (
                                <div
                                    className="popup"
                                    style={{
                                        color: isSuccess ? '#155724' : '#721c24',
                                        backgroundColor: isSuccess ? '#d4edda' : '#f8d7da',
                                        border: isSuccess ? '1px solid #d4edda' : '1px solid #f8d7da'
                                    }}
                                >
                                    <p className="popup-text">{popupMessage}</p>
                                </div>
                            )}
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default ZoneZappLandingPage;