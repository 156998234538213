import React, { useState, useEffect } from 'react';
import './BackTotop.css';
import ExpandLess from '@mui/icons-material/ExpandLess';



const BackToTopButton = () => {
  const [isVisible, setIsVisible] = useState(false);

  const handleScroll = () => {
    // Show button if scrolled more than 100px from the top
    setIsVisible(window.scrollY > 100);
  };

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  const scrollToTop = () => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  };

  return (
    <button
      className={`back-to-top ${isVisible ? 'visible' : ''}`}
      onClick={scrollToTop}
    >
      <ExpandLess className='expandless-icon' />
    </button>
  );
};

export default BackToTopButton;
