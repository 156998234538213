// Validate environment variables
// const validateEnvVariable = (key, value) => {
//     if (!value) {
//         throw new Error(`${key} is not defined in the environment variables.`);
//     }
// };
const validateEnvVariable = (key, value) => {
    if (!value) {
        console.warn(`${key} is not defined in the environment variables.`);
    }
    return value || "success"; // Provide a default empty string or another fallback.
};


// Environment variables
const CONTACT_FORM_ENDPOINT = process.env.REACT_APP_CONTACT_FORM_ENDPOINT;
const FOOTER_SUBSCRIPTION_ENDPOINT = process.env.REACT_APP_FOOTER_SUBSCRIPTION_ENDPOINT;
const GET_IN_TOUCH_MODEL_ENDPOINT = process.env.REACT_APP_GET_IN_TOUCH_MODEL_ENDPOINT;
const ZONEZAPP_LANDING_PAGE_ENDPOINT = process.env.REACT_APP_ZONEZAPP_LANDING_PAGE_ENDPOINT;
const CHAT_ASSISTANT_ENDPOINT = process.env.REACT_APP_CHAT_ASSISTANT_ENDPOINT;

// Validate each variable
validateEnvVariable("CONTACT_FORM_ENDPOINT", CONTACT_FORM_ENDPOINT);
validateEnvVariable("FOOTER_SUBSCRIPTION_ENDPOINT", FOOTER_SUBSCRIPTION_ENDPOINT);
validateEnvVariable("GET_IN_TOUCH_MODEL_ENDPOINT", GET_IN_TOUCH_MODEL_ENDPOINT);
validateEnvVariable("ZONEZAPP_LANDING_PAGE_ENDPOINT", ZONEZAPP_LANDING_PAGE_ENDPOINT);
validateEnvVariable("CHAT_ASSISTANT_ENDPOINT", CHAT_ASSISTANT_ENDPOINT);

// Export the validated endpoints
export default {
    CONTACT_FORM_ENDPOINT,
    FOOTER_SUBSCRIPTION_ENDPOINT,
    GET_IN_TOUCH_MODEL_ENDPOINT,
    ZONEZAPP_LANDING_PAGE_ENDPOINT,
    CHAT_ASSISTANT_ENDPOINT,
};