import React, { useState, useEffect, useRef } from 'react';
import './Stepper.css';
import AppShowcaseOne from '../../assets/images/steper-1.jpeg';
import AppShowcaseTwo from '../../assets/images/steper-2.jpeg';
import AppShowcaseThree from '../../assets/images/steper-3.jpg';
import AppShowcaseFour from '../../assets/images/steper-4.jpeg';

import Slider from "react-slick";
import { Trans } from 'react-i18next';
import { useTranslation } from 'react-i18next';


const Stepper = () => {

    const { t } = useTranslation();

    const steps = [
        {
            title: `${t('op.stepper.steppers.step-1.label')}`,
            description: `${t('op.stepper.steppers.step-1.description')}`,
            image: AppShowcaseOne,
        },
        {
            title: `${t('op.stepper.steppers.step-2.label')}`,
            description: `${t('op.stepper.steppers.step-2.description')}`,
            image: AppShowcaseTwo,
        },
        {
            title: `${t('op.stepper.steppers.step-3.label')}`,
            description: `${t('op.stepper.steppers.step-3.description')}`,
            image: AppShowcaseThree,
        },
        {
            title: `${t('op.stepper.steppers.step-4.label')}`,
            description: `${t('op.stepper.steppers.step-4.description')}`,
            image: AppShowcaseFour,
        }
    ];

    const [currentSlide, setCurrentSlide] = useState(0);
    const stepperRef = useRef(null); // Ref for the stepper section
    const titleRef = useRef(null);   // Ref for the heading
    const mobileFrameRef = useRef(null); // Ref for the mobile frame
    const [isInView, setIsInView] = useState(false); // State to track if the section is in view

    const settings = {
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        autoplay: true,  // Enable automatic sliding
        autoplaySpeed: 3000,  // 3 seconds
        beforeChange: (current, next) => setCurrentSlide(next), // Track slide change
    };

    // Calculate progress percentage based on the current slide
    const progressPercentage = ((currentSlide + 1) / steps.length) * 100;

    useEffect(() => {
        const options = {
            root: null, // viewport
            threshold: 0.1, // 10% visibility
        };

        const observer = new IntersectionObserver((entries) => {
            entries.forEach((entry) => {
                if (entry.isIntersecting) {
                    setIsInView(true); // Trigger animation once section is visible
                }
            });
        }, options);

        // Observe the stepper section
        if (stepperRef.current) {
            observer.observe(stepperRef.current);
        }

        // Cleanup observer when component unmounts
        return () => {
            if (stepperRef.current) observer.unobserve(stepperRef.current);
        };
    }, []);

    return (
        <section id="stepper" ref={stepperRef} className="stepper-wrapper">
            <div className="carousel-container">
                <h1 ref={titleRef} className={`heading ${isInView ? 'animate__animated animate__fadeInDown' : ''}`}>
                    <Trans i18nKey="op.stepper.header">
                        <span className='color'>How Does</span> ZoneZapp <span className='color'>Work?</span>
                    </Trans>
                </h1>
                <div ref={mobileFrameRef} className={`mobile-frame ${isInView ? 'animate__animated animate__rollIn' : ''}`}>
                    <Slider {...settings}>
                        {steps.map((step, index) => (
                            <div key={index} className="step-slide">
                                <img src={step.image} alt={`Step ${index + 1}`} className={`step-image ${isInView ? 'animate__animated animate__rollIn animate__delay-2s' : ''}`} />
                                <div className="step-info">
                                    <h2 className={`${isInView ? 'animate__animated animate__fadeInLeft animate__delay-3s' : ''}`}>{step.title}</h2>
                                    <p className={`text-center ${isInView ? 'animate__animated animate__fadeInRight animate__delay-3s' : ''}`}>{step.description}</p>
                                </div>
                            </div>
                        ))}
                    </Slider>
                </div>
            </div>
            {/* Move Progress Bar to the footer of stepper-wrapper */}
            <div className="progress-bar-wrapper">
                <div className="progress-bar" style={{ width: `${progressPercentage}%` }}></div>
            </div>
        </section>
    );
};

export default Stepper;