// src/i18n.js
import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';

// Import translations (you can structure these differently if preferred)
import en from './locales/en.json';
import fr from './locales/fr.json';
import es from './locales/es.json';

i18n
  .use(LanguageDetector) // Detects user's language preference
  .use(initReactI18next)  // Bind i18n to React
  .init({
    resources: {
      en: { translation: en },
      fr: { translation: fr },
      es: { translation: es },
    },
    detection: {
      order: ['path', 'cookie', 'htmlTag', 'localStorage', 'subdomain'],
      caches: ['cookie']
    },
    fallbackLng: 'en', // Use English if language is not available
    interpolation: { escapeValue: false }, // React already does escaping
  });

export default i18n;