import React, { useEffect, useRef } from 'react';
import './Mission.css';

import { useTranslation } from 'react-i18next';

const Mission = () => {
    // Refs for elements that will have animations
    const missionSectionRef = useRef(null);
    const missionHeadingRef = useRef(null);
    const missionTextRef = useRef(null);
    const { t } = useTranslation();


    useEffect(() => {
        const options = {
            root: null, // The viewport is the root
            threshold: 0.1, // 10% of the section should be visible to trigger the animation
        };

        const observer = new IntersectionObserver((entries) => {
            entries.forEach((entry) => {
                if (entry.isIntersecting && entry.target.id === 'mission') {
                    // Apply animation classes when the mission section is visible
                    if (missionHeadingRef.current) {
                        missionHeadingRef.current.classList.add('animate__animated', 'animate__fadeInDown', 'animate__delay-2s');
                    }
                    if (missionTextRef.current) {
                        missionTextRef.current.classList.add('animate__animated', 'animate__fadeInUp', 'animate__delay-2s');
                    }
                }
            });
        }, options);

        // Observe the entire mission section
        if (missionSectionRef.current) {
            observer.observe(missionSectionRef.current);
        }

        // Cleanup observer when component unmounts
        return () => {
            if (missionSectionRef.current) observer.unobserve(missionSectionRef.current);
        };
    }, []);

    return (
        <section id="mission" ref={missionSectionRef} className='mission-wrapper'>
            <div className="cover-container">
                <div className="overlay"></div>
                <div className="content">
                    {/* Mission heading */}
                    <h2 ref={missionHeadingRef} className="features-heading pb-2 text-center mission-heading">
                        {t('op.mission.header')}
                    </h2>

                    {/* Mission text */}
                    <div className="col-lg-6 mx-auto">
                        <p ref={missionTextRef} className="mission-text mb-4">
                            {t('op.mission.sub-text')}
                        </p>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default Mission;